/**
 * Set default settings for jquery validate plugin
 * -------------------------------------------------------------------------------
 */
var svgAlertLabel = '<svg xmlns:xlink="http://www.w3.org/1999/xlink"><use xlink:href="#alert-icon"/></svg>';
var alertIcon = '<div class="alert-icon"><svg xmlns:xlink="http://www.w3.org/1999/xlink"><use xlink:href="#alert-icon"/></svg></div>';

$.validator.setDefaults({
  errorClass: 'has-error',
  errorElement: 'div',
  highlight: function (element, errorClass, validClass) {
    $(element).closest('.form-group').addClass('has-error-fields');
    $(element).addClass(errorClass).removeClass(validClass);
  },
  unhighlight: function (element, errorClass, validClass) {
    $(element).parent('.form-group').removeClass('has-error-fields');
    $(element).removeClass(errorClass).addClass(validClass);
  },
  errorPlacement: function (error, element) {
    error.addClass('alert-label').append(svgAlertLabel);
    error.insertBefore(element);

    $(alertIcon).insertBefore(error);
  },
  debug: false
});

/**
 * Additional Validation Rules (extracted from additional-methods.js)
 */
$.validator.addMethod('alphanumeric', function (value, element) {
  return this.optional(element) || /^\w+$/i.test(value);
}, 'Letters, numbers, and underscores only please');

// $.validator.addMethod('etiname', function (value, element) {
//   return this.optional(element) || /^[a-zA-Z\u0600-\u06FF\s]{1,248}\$/i.test(value);
// }, 'Letters latin and arabic, numbers, and spaces only please');

// accept only letters and digits, spaces, no underscore
$.validator.addMethod('realalphanumeric', function (value, element) {
  return this.optional(element) || /^[a-zA-Z\s0-9]+$/i.test(value);
}, 'Letters, numbers, and spaces only please');

// accept only letters and digits, spaces, no underscore
$.validator.addMethod('realalphanumericnospace', function (value, element) {
  return this.optional(element) || /^[a-zA-Z0-9]+$/i.test(value);
}, 'Letters and numbers only please');


// accept only letters and spaces only
$.validator.addMethod('realalphabetic', function (value, element) {
  return this.optional(element) || /^[a-zA-Z\s]+$/i.test(value);
}, 'Letters, and spaces only please');


// accept only letters and digits, spaces, no underscore
$.validator.addMethod('realalphabeticlatinarabic', function (value, element) {
  return this.optional(element) || /^[a-zA-Z\u0600-\u06ff\u0750-\u077f\ufb50-\ufbc1\ufbd3-\ufd3f\ufd50-\ufd8f\ufd92-\ufdc7\ufe70-\ufefc\uFDF0-\uFDFD\s0-9]+$/i.test(value);
}, 'Letters, numbers, and spaces only please');


$.validator.addMethod('integer', function (value, element) {
  return this.optional(element) || /^-?\d+$/.test(value);
}, 'A positive or negative non-decimal number please');


$.validator.addMethod('lettersonly', function (value, element) {
  return this.optional(element) || /^[a-z]+$/i.test(value);
}, 'Letters only please');

$.validator.addMethod('letterswithbasicpunc', function (value, element) {
  return this.optional(element) || /^[a-z\-.,()'"\s]+$/i.test(value);
}, 'Letters or punctuation only please');


$.validator.addMethod('notEqualTo', function (value, element, param) {
  return this.optional(element) || !$.validator.methods.equalTo.call(this, value, element, param);
}, 'Please enter a different value, values must not be the same.');

/**
 * Return true if the field value matches the given format RegExp
 *
 * @example $.validator.methods.pattern("AR1004",element,/^AR\d{4}$/)
 * @result true
 *
 * @example $.validator.methods.pattern("BR1004",element,/^AR\d{4}$/)
 * @result false
 *
 * @name $.validator.methods.pattern
 * @type Boolean
 * @cat Plugins/Validate/Methods
 */
$.validator.addMethod('pattern', function (value, element, param) {
  if (this.optional(element)) {
    return true;
  }
  if (typeof param === 'string') {
    param = new RegExp('^(?:' + param + ')$');
  }
  return param.test(value);
}, 'Invalid format.');

/**
 * Return true, if the value is a valid date, also making this formal check dd/mm/yyyy.
 *
 * @example $.validator.methods.date("01/01/1900")
 * @result true
 *
 * @example $.validator.methods.date("01/13/1990")
 * @result false
 *
 * @example $.validator.methods.date("01.01.1900")
 * @result false
 *
 * @example <input name="pippo" class="{dateITA:true}" />
 * @desc Declares an optional input element whose value must be a valid date.
 *
 * @name $.validator.methods.dateITA
 * @type Boolean
 * @cat Plugins/Validate/Methods
 */
$.validator.addMethod('dateITA', function (value, element) {
  var check = false;
  var re = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
  var adata;
  var gg;
  var mm;
  var aaaa;
  var xdata;
  if (re.test(value)) {
    adata = value.split('/');
    gg = parseInt(adata[0], 10);
    mm = parseInt(adata[1], 10);
    aaaa = parseInt(adata[2], 10);
    xdata = new Date(Date.UTC(aaaa, mm - 1, gg, 12, 0, 0, 0));
    if ((xdata.getUTCFullYear() === aaaa) && (xdata.getUTCMonth() === mm - 1) && (xdata.getUTCDate() === gg)) {
      check = true;
    } else {
      check = false;
    }
  } else {
    check = false;
  }
  return this.optional(element) || check;
}, $.validator.messages.date);


/**
 * Custom password rule that respect Etisalat rules
 */
$.validator.addMethod('etipassword', function (value, element) {
  return this.optional(element) || /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9@._-]{5,248})/.test(value);
}, 'Password not valid');


/**
 * Custom username/userid rule that respect Etisalat rules
 */
$.validator.addMethod('userid', function (value, element) {
  return this.optional(element) || /^(?=.*[0-9a-zA-Z])([a-zA-Z0-9@._-]{5,100})$/.test(value);
}, 'Username not valid');


/**
 * Mutual Exclusive fields validation
 */
$.validator.addMethod('mutualex', function (value, element, param) {
  // Current value, Validated element, Parameters

  var target = $(param);
  var source = $(element);

  if (source.val().length === 0 && target.val().length === 0) {
    return false;
  }

  if (source.val().length > 0 && target.val().length > 0) {
    return false;
  }

  return true;
}, 'These 2 fields cannot be filled simultaneausly');


/**
 * Custom email validation
 */
$.validator.addMethod('email2', function (value, element) {
  /* eslint-disable no-control-regex, max-len */
  return this.optional(element) || /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i.test(value);
}, 'Please enter a valid email address.');

//

// if the page is set for arabic language, then set the default messages for arabic
$('html').attrchange({
  trackValues: true,
  callback: function(e) {
    if (e.attributeName === 'dir') {
      if ($('html').attr('lang') === 'en') {
        $.extend($.validator.messages, {
          required: 'This field is required.',
          remote: 'Please fix this field.',
          email: 'Please enter a valid email address.',
          url: 'Please enter a valid URL.',
          date: 'Please enter a valid date.',
          dateISO: 'Please enter a valid date (ISO).',
          number: 'Please enter a valid number.',
          digits: 'Please enter only digits.',
          equalTo: 'Please enter the same value again.',
          maxlength: $.validator.format('Please enter no more than {0} characters.'),
          minlength: $.validator.format('Please enter at least {0} characters.'),
          rangelength: $.validator.format('Please enter a value between {0} and {1} characters long.'),
          range: $.validator.format('Please enter a value between {0} and {1}.'),
          max: $.validator.format('Please enter a value less than or equal to {0}.'),
          min: $.validator.format('Please enter a value greater than or equal to {0}.'),
          step: $.validator.format('Please enter a multiple of {0}.')
        });
      }
      if ($('html').attr('lang') === 'ar') {
        /*
          * Translated default messages for the jQuery validation plugin.
          * Locale: AR (Arabic; العربية)
          */
        $.extend($.validator.messages, {
          required: 'هذا الحقل إلزامي',
          remote: 'يرجى تصحيح هذا الحقل للمتابعة',
          email: 'رجاء إدخال عنوان بريد إلكتروني صحيح',
          email2: 'رجاء إدخال عنوان بريد إلكتروني صحيح',
          url: 'رجاء إدخال عنوان موقع إلكتروني صحيح',
          date: 'رجاء إدخال تاريخ صحيح',
          dateISO: 'رجاء إدخال تاريخ صحيح (ISO)',
          number: 'رجاء إدخال عدد بطريقة صحيحة',
          digits: 'رجاء إدخال أرقام فقط',
          creditcard: 'رجاء إدخال رقم بطاقة ائتمان صحيح',
          equalTo: 'رجاء إدخال نفس القيمة',
          extension: 'رجاء إدخال ملف بامتداد موافق عليه',
          maxlength: $.validator.format('الحد الأقصى لعدد الحروف هو {0}'),
          minlength: $.validator.format('الحد الأدنى لعدد الحروف هو {0}'),
          rangelength: $.validator.format('عدد الحروف يجب أن يكون بين {0} و {1}'),
          range: $.validator.format('رجاء إدخال عدد قيمته بين {0} و {1}'),
          max: $.validator.format('رجاء إدخال عدد أقل من أو يساوي (0}'),
          min: $.validator.format('رجاء إدخال عدد أكبر من أو يساوي (0}')
        });
      }
    }
  }
});


/**
 * Default Select2 settings and initialization
 */
// initialize select 2
var select2input = $('.floating-label-select select');
select2input.each(function () {

  var $select = $(this);

  $select.select2({
    width: '100%',
    dropdownParent: $select.parent('.floating-label-select'),
    minimumResultsForSearch: Infinity
  }).on('select2:opening', function () {
    $(this).closest('.floating-label-select').find('label').addClass('floating-label');
  }).on('select2:selecting', function () {
    $(this).closest('.floating-label-select').addClass('floating-label-selected');
  }).on('select2:close', function () {
    if (!$(this).closest('.floating-label-select').hasClass('floating-label-selected')) {
      $(this).closest('.floating-label-select').find('label').removeClass('floating-label');
    }
  });


  // if a select has no selected option, then reset it
  if ($('option', $select).filter(':selected').text().trim() === '') {
    $select.closest('.floating-label-select').find('label').removeClass('floating-label');
  } else {
    // if there is an option already selected from start, then fix the label
    $select.addClass('selected').closest('.floating-label-select').find('label').addClass('floating-label');
  }

});

// control empty :not(:required) inputs
$('input:not(:required)').each(function () {
  if ($(this).val()) {
    $(this).siblings('label').addClass('floating-label');
  }
});
// control empty :not(:required) inputs on focus/blur
$('input:not(:required)').focus(function () {
  $(this).siblings('label').removeClass('floating-label');
}).blur(function () {
  if ($(this).val()) {
    $(this).siblings('label').addClass('floating-label');
  }
});


// disable paste
$('input[type=password]').bind('paste', function () {
  return false;
});


// masked input
var inputMasked = $('.masked-input >input.input-masked');
$(inputMasked).keydown(function () {
  $(this).siblings('input:not(.input-masked)').focus();
});
var inputHidden = $('.masked-input >input:not(.input-masked)');
$(inputHidden).keyup(function () {
  $(this).siblings('input.input-masked').focus();
});
$(inputHidden).bind('input', function () {
  var inputMasked = $(this).siblings('input.input-masked');
  var inputHiddenVal = $(this).val();
  var mask = '';
  // mask = inputHiddenVal.replace(/(^.{3})/g, '$1 ');
  var maskLength = 3;
  var maskVal = '\u2022';
  if (inputHiddenVal.length > maskLength) {
    var maskSplit = inputHiddenVal.split('');
    for (var maskKey = maskLength; maskKey < maskSplit.length; maskKey++) {
      maskSplit.splice(maskKey, 1, maskVal);
    }
    mask = maskSplit.join('');
  } else {
    mask = inputHiddenVal;
  }
  inputMasked.focus().val(mask);
  if ($(this).closest('.masked-input').hasClass('has-error-fields')) {
    $(this).closest('.masked-input').addClass('had-error-fields');
  } else {
    $(this).closest('.masked-input').removeClass('had-error-fields');
  }
});


// activate forms
$(document).ready(function () {
  $('form:not(#search-form):not(#store-locator-form)').attr('data-active', 'true');
});
